<template>
  <v-subheader class="text--text subtitle-1 px-0 d-flex justify-space-between">
    <div class="d-flex align-center">
      <v-icon
        color="text"

        class="mr-1 mr-sm-2"
      >
        mdi-{{ iconName }}
      </v-icon>
      {{ text }}
    </div>

    <slot></slot>
  </v-subheader>
</template>

<script>
export default {
  name: 'TabHeader',
  props: {
    iconName: String,
    text: String,
  },
};
</script>

<style scoped>

</style>
