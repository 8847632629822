<template>
  <v-expand-transition
    mode="in-out"
  >
    <v-row
      v-if="isAuthenticated"
      no-gutters
      class="flex-column"
    >
      <notification
        v-if="errorNotification"
        type="error"
      >
        Щось пішло не так. Спробуйте ще раз
      </notification>

      <tab-header
        class="mb-2 card-wrapper mx-auto mx-md-0"
        icon-name="credit-card-outline"
        text="Мої картки | Картки для отримання коштів"
      />

      <v-row
        no-gutters
        class="flex-column flex-md-row"
      >
        <v-col
          ref="cardsWrapper"
          class="pa-0 card-wrapper mx-auto col-md-6 position-relative"
        >
          <block-preloader
            v-if="blockPreloaderIsVisible"
            background-color="background"
          />
          <template v-if="clientCards.length > 0">
            <v-card
              v-for="(card, index) of sortedCardsArr()"
              :key="index"
              :class="['box-shadow-none col-md-10 pa-0 cursor-pointer', { 'mt-5': index > 0 },
                       { 'visibility-hidden': blockPreloaderIsVisible }]"
            >
              <v-card-text
                class="border-green card border-radius-10"
                title="Натисніть, щоб зробити карту основною"
                @click="makeBankCardMain(card.silver_id, card.is_default)"
              >
                <v-row
                  no-gutters
                  class="white--text"
                >
                  <v-col
                    v-if="card.is_default"
                    class="pa-0 success border-radius-5 text-center col-5 col-sm-4 mr-2"
                  >
                    Основна
                  </v-col>
                  <v-col
                    v-if="card.if_valid"
                    class="pa-0 primary border-radius-5 text-center col-5 col-sm-4"
                  >
                    Погоджена
                  </v-col>
                  <v-col
                    v-else
                    class="pa-0 secondary border-radius-5 text-center col-5 col-sm-4 mr-2"
                  >
                    Деактивована
                  </v-col>
                </v-row>

                <v-row
                  no-gutters
                  class="flex-column mt-5 mt-sm-8"
                >
                  <v-col
                    class="pa-0 text-h6 success--text border-green border-radius-10 text-center
                              py-1 text-sm-h5"
                  >
                    {{ card.card_mask }}
                  </v-col>
                  <v-col class="pa-0 ml-2 d-flex align-center">
                    <img
                      v-if="isVisa(card)"
                      src="@/assets/visa.png"
                      alt=""
                      class="visa-logo mt-2"
                    >
                    <img
                      v-else
                      src="@/assets/master_card.png"
                      alt=""
                      class="mastercard-logo mt-1 ml-n2"
                    >
                  </v-col>
                </v-row>

                <v-row
                  no-gutters
                  class="mt-4 mt-sm-8 justify-space-between"
                >
                  <v-col class="border-black border-radius-15 text-center text--text col-5">
                    {{ card.bank_name }}
                  </v-col>

                  <v-col
                    v-if="false"
                    class="text-end text--text"
                  >
                    Дійсна до
                    <span class="text-decoration-underline">
                      02/2022
                    </span>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
          <template v-else>
            <p class="text--text text-h6 text-center text-sm-start">
              Ви ще не додали жодної банківської картки
            </p>
          </template>
        </v-col>

        <v-col
          class="pa-0 mt-7 add-card-wrapper mx-auto mt-md-0 d-md-flex justify-end align-start"
          :class="{ 'visibility-hidden': blockPreloaderIsVisible }"
        >
          <div
            class="d-flex flex-column align-center secondBg border-radius-10 pt-5 pb-6 col-md-8
                   box-shadow"
          >
            <p class="mb-2">
              Додати іншу картку
            </p>
            <v-btn
              rounded
              color="success"
              :to="{ name: addNewBankCardPath }"
            >
              Додати
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-row>
  </v-expand-transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import blockPreloaderMixin from '@/mixins/blockPreloaderMixin';
import errorNotificationMixin from '@/mixins/errorNotificationMixin';
import Notification from '@/components/common/Notification';
import TabHeader from '@/components/common/TabHeader';
import BlockPreloader from '@/components/common/BlockPreloader';
import RouteNamesService from '@/services/route-names-service';
import pageLoaderMixin from '../mixins/pageLoaderMixin';

export default {
  name: 'UserBankCards',
  // eslint-disable-next-line vue/no-unused-components
  components: { BlockPreloader, TabHeader, Notification },
  mixins: [blockPreloaderMixin, errorNotificationMixin, pageLoaderMixin],
  title: 'Картки',
  data() {
    return {
      addNewBankCardPath: RouteNamesService.addNewBankCard,
      cards: [
        {
          isDefault: true,
          cardMask: '**** **** **** 8848',
          date: '02/2022',
          ifValid: true,
        },
        {
          isDefault: false,
          cardMask: '**** **** **** 8888',
          date: '02/2022',
          ifValid: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters('auth', [
      'isAuthenticated',
    ]),
    clientCards: {
      get() {
        return this.$store.state.bankCards.clientCards;
      },
      set(value) {
        this.$store.commit('bankCards/UPDATE_STATE', {
          key: 'clientCards',
          value,
        });
      },
    },
  },
  beforeMount() {
    this.getCardsStore()
      .then(() => this.hidePagePreloader())
      .catch((err) => console.error(err));
  },
  methods: {
    ...mapActions('bankCards', [
      'getCardsStore',
      'makeBankCardMainStore',
    ]),
    isVisa(card) {
      return card.cardType && card.cardType.toLowerCase() === 'visa';
    },
    sortedCardsArr() {
      const mainCard = this.clientCards.find((el) => el.is_default === true);
      const filteredArr = this.clientCards.filter((el) => el.is_default !== true);
      filteredArr.unshift(mainCard);
      return filteredArr;
    },
    setCardsWrapperHeight() {
      this.$refs.cardsWrapper.style.minHeight = `${this.$refs.cardsWrapper.offsetHeight}px`;
    },
    makeBankCardMain(cardId, bool) {
      if (!bool) {
        this.toggleBlockPreloader(true);
        this.makeBankCardMainStore(cardId)
          .then((result) => {
            if (result) {
              this.setCardsWrapperHeight();
              this.getCardsStore()
                // eslint-disable-next-line no-return-assign
                .then(() => this.toggleBlockPreloader(false))
                .catch((err) => console.log(err));
            } else {
              this.toggleBlockPreloader(false);
              this.toggleErrorMessage();
            }
          }).catch((err) => console.log(err));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .card-wrapper {
    @media screen and (max-width: 960px) {
      @media screen and (min-width: 400px) {
        max-width: 390px;
      }

      @media screen and (min-width: 600px) {
        width: 500px;
      }
    }
  }

  .card {
    transition: all .3s ease;

    &:hover {
      box-shadow: 4px 3px 5px 3px rgba(0, 0, 0, .16);
    }
  }

  .add-card-wrapper {
    @media screen and (min-width: 400px) and (max-width: 960px) {
      max-width: 390px;
    }
  }

  .visa-logo {
    height: 10px;
  }

  .mastercard-logo {
    height: 23px;
  }
</style>
